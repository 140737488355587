import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import LoginLayout from '../layouts/LoginLayout'
import { login, unsetError} from '../store/actions/useAuth'
import { navigate, Link } from 'gatsby'

const Login = props => {
  const dispatch = useDispatch();

  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const loginCargando = useSelector( state => state.auth.loading );
  const loggedIn = useSelector( state => state.auth.loggedIn );
  
  console.log(process.env.NODE_ENV, process.env.STRAPI_URL);

  const handleSubmit = async (event) => {
    setError("")
    event.preventDefault()
    if ( identifier === "" ){
      setError("Debe ingresar usuario/email de acceso.")
      return false;
    }else if ( password==="" ){
      setError("Debe ingresar el password.")
      return false;
    }
    try{
      const retlogin = await dispatch(login({ identifier, password }) )
      console.log("login", retlogin)
      if(retlogin.status){
        navigate("/app/dashboard")
      }
      else{
        setError(retlogin.msg);
      }
    }catch(e){
      console.error(e)
    }
  }
  
  useEffect(() =>{
    if(loggedIn){
      navigate("/app/dashboard")
    }
    dispatch(unsetError())
  },[])

  return (
    (loggedIn)?
    ""
    :
    <LoginLayout title="Gestión B - Login">
        <div className="flex-column">
          <h1>¡Hola!</h1>
          <p>Ingresa tu usuario y contraseña.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="text-uppercase">Usuario</label>
              <input 
                className="fadeIn second"
                onChange={ e => {
                setIdentifier(e.target.value)
                }}
                value={ identifier }
                disabled={loginCargando}
                id="username" type="email" className="form-control" name="email" aria-describedby="emailHelp" placeholder="Tu dirección de email" />
            </div>
            <div className="form-group">
              <label className="text-uppercase">Contraseña</label>
              <input 
                className="fadeIn third"
                onChange={ e => {
                setPassword(e.target.value)
                }}
                value={ password }
                disabled={loginCargando}
                type="password" className="form-control" id="password" name="password" placeholder="Contraseña" />
            </div>
            <div className="form-check">
              {/* <input type="checkbox" className="form-check-input" name="recordar" id="recordar" /> */}
              {/* <span className="form-check-label">Recordar mis datos</span> */}
            </div>
            <button type="submit" className="btn" disabled={loginCargando}>Ingresar</button>
          </form>

          {/* <!-- Remind Passowrd --> */}
          <div className="formFooter">
              { (error && error.length > 1) && (
                  <p className="alert alert-danger">
                  { error }
                  </p>
              )}
          </div>
          <div className="formFooter font-weight-bold">
          ¿Olvidaste tu contraseña? Haz click <Link to="/forgot-password">aquí</Link>
          </div>
        </div>
    </LoginLayout>
  );
}

export default Login;